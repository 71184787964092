import DateRange from 'app/components/cores/date-range';
import MultipleSelect from 'app/components/cores/select/multiple-select';
import SingleSelect from 'app/components/cores/select/single-select';
import { generateDefaultDateRange } from 'domains/report/performance.domain';
import _ from 'lodash';
import { TOption, TUserLocation } from 'models';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';

import { LocationValueContainer, getDefaultDateRange } from './commons';
import { TDateRangeValue, TLocationValue } from './type';

export const useStyles = makeStyles(() => ({
  menu: {
    width: '512px !important',
  },
  flex: {
    display: 'flex !important',
  },
  dateRangeInput: {
    height: '3.2rem !important',
    borderRadius: 8,
  },
}));

type Props = {
  defaultFilter?: any;
  onChange?: (filter: any) => void;
};

function PerformanceReportFilter({ defaultFilter, onChange }: Props) {
  // ===== locations
  const locations = useSelector(({ auth }: any) => auth.user.locations);
  const locationOptions: TUserLocation[] = useMemo(() => _.orderBy(locations, 'name'), [locations]);
  const [selectedLocations, setSelectedLocations] = useState<TLocationValue[]>([]);

  const handleSelectLocation = (values: any) => {
    const nextSelectedLocations =
      _.map(values, (location: TUserLocation) => _.pick(location, ['externalId', 'name'])) || [];
    const nextSelectedLocationIds = _.map(nextSelectedLocations, 'externalId');
    setSelectedLocations(nextSelectedLocations);
    onChange?.({ 'q[location_id_in][]': nextSelectedLocationIds });
  };
  // end locations

  // ===== frequency
  const [selectedFrequency, setSelectedFrequency] = useState<TOption | null>(null);

  const frequencyOptions = useMemo(
    () => [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
      { value: 'monthly', label: 'Monthly' },
    ],
    [],
  );

  const handleFrequencyChange = (item: any) => {
    setSelectedFrequency(item);
    onChange?.({ 'q[kind_eq]': item.value });
  };
  // end frequency

  // ===== date range
  const [selectedDateRange, setSelectedDateRange] = useState<TDateRangeValue>(
    getDefaultDateRange(defaultFilter, {
      fromDateName: 'q[from_date_eq]',
      toDateName: 'q[to_date_eq]',
      dateFormat: 'YYYY-MM-DD',
    }),
  );

  const handleDateRangeChange = (values: TDateRangeValue) => {
    if (!values.startDate || !values.endDate) {
      const { startDate, endDate } = generateDefaultDateRange();
      setSelectedDateRange({ startDate, endDate });
      onChange?.({
        'q[from_date_eq]': startDate,
        'q[to_date_eq]': endDate,
      });
      return;
    }

    setSelectedDateRange(values);
    onChange?.({
      'q[from_date_eq]': moment(values?.startDate).format('YYYY-MM-DD'),
      'q[to_date_eq]': moment(values?.endDate).format('YYYY-MM-DD'),
    });
  };
  // end date range

  // ===== common
  const classes = useStyles();
  const isMounted = useRef(false);

  // set default filter value when page reloaded
  useEffect(() => {
    if (isMounted.current || !defaultFilter || !locations) return;
    isMounted.current = true;

    // set default locations
    const defaultLocationFilterValue = defaultFilter?.['q[location_id_in][]'];
    if (defaultLocationFilterValue) {
      const filteredLocations = _.filter(locations, (location: TUserLocation) => {
        return Array.isArray(defaultLocationFilterValue)
          ? _.find(defaultLocationFilterValue, d => Number(d) === location?.externalId)
          : Number(defaultLocationFilterValue) === location?.externalId;
      });

      const nextSelectedLocations = _.map(filteredLocations, (location: TUserLocation) =>
        _.pick(location, ['externalId', 'name']),
      ) as any;

      if (nextSelectedLocations?.length) {
        setSelectedLocations(nextSelectedLocations);
      }
    }

    // set default frequency
    const defaultKindFilterValue = defaultFilter?.['q[kind_eq]'];
    if (defaultKindFilterValue) {
      const nextSelectedFrequency = _.find(frequencyOptions, { value: defaultKindFilterValue }) || null;
      setSelectedFrequency(nextSelectedFrequency);
    }
  }, [defaultFilter, locations, frequencyOptions]);

  return (
    <div className="flex items-center pr-24 space-x-8">
      <div className="w-232">
        <MultipleSelect
          placeholder="Locations"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={selectedLocations}
          options={locationOptions || []}
          getOptionLabel={option => `${(option as TUserLocation).name}`}
          getOptionValue={option => `${(option as TUserLocation).externalId}`}
          components={{ ValueContainer: LocationValueContainer }}
          onChange={handleSelectLocation}
        />
      </div>

      <div className="w-224">
        <DateRange
          classes={{ input: classes.dateRangeInput }}
          value={selectedDateRange}
          onChange={handleDateRangeChange}
        />
      </div>

      <div className="w-136">
        <SingleSelect
          minMenuHeight={400}
          menuPlacement="auto"
          placeholder="Frequency"
          value={selectedFrequency}
          options={frequencyOptions}
          styles={{
            singleValue: (baseStyles: any) => ({
              ...baseStyles,
              '& *': {
                fontWeight: '400 !important',
              },
            }),
          }}
          onChange={handleFrequencyChange}
        />
      </div>
    </div>
  );
}

export default PerformanceReportFilter;
