import ErrorMessage from 'app/components/cores/form/error-message';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { Props as RSelectProps } from 'react-select';

import SelectInfiniteScroll from './select-infinite-scroll';

type Props = {
  name: string;
  control?: Control;
  rules?: any;
  allOptionsPlaceholder?: boolean;
  outputSelection?: (newValues: any) => any;
  [key: string]: any;
} & RSelectProps;

function ControlledSelectInfiniteScroll({ name, control, rules, outputSelection, ...selectProps }: Props) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (newValues: any) => {
    if (!outputSelection) {
      onChange(newValues);
      return;
    }
    onChange(outputSelection(newValues));
  };

  const isMulti = selectProps?.isMulti;

  const presentValue = useMemo(() => {
    if (!isMulti) {
      return !value?.id ? null : value;
    }
    return !value?.length ? null : value;
  }, [isMulti, value]);

  return (
    <div>
      <SelectInfiniteScroll
        {...selectProps}
        value={presentValue}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default ControlledSelectInfiniteScroll;
