import DateRange from 'app/components/cores/date-range';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { convertDateStringToString } from 'utils/string';

type Props = {
  historyFilters: any;
  setHistoryFilters: (filters: any) => void;
};

function HistoriesHeaderFilter({ historyFilters, setHistoryFilters }: Props) {
  const startDateKey = 'q[completed_at_gteq]';
  const endDateKey = 'q[completed_at_lteq]';

  const defaultSelectedSubmittedRange = useMemo(() => {
    if (!historyFilters[startDateKey] || !historyFilters[endDateKey]) {
      return {
        startDate: null,
        endDate: null,
      };
    }

    const startDate = moment(historyFilters[startDateKey], 'DD/MM/YYYY').toDate();
    const endDate = moment(historyFilters[endDateKey], 'DD/MM/YYYY').toDate();

    return { endDate, startDate };
  }, [historyFilters]);

  const [selectedSubmittedRange] = useState(defaultSelectedSubmittedRange);

  const handleSelect = ({ startDate, endDate }: any) => {
    setHistoryFilters((prevFilter: any) => ({
      ...prevFilter,
      'q[completed_at_gteq]': convertDateStringToString(startDate),
      'q[completed_at_lteq]': convertDateStringToString(endDate),
    }));
  };

  return (
    <DateRange
      onChange={handleSelect}
      value={selectedSubmittedRange}
      maxDate={new Date()}
    />
  );
}

export default HistoriesHeaderFilter;
